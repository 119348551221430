import buffaloSafariImg from "../../../assets/game_thumbnails/buffalo-safari.png";
import chinaStreetImg from "../../../assets/game_thumbnails/china-street.png";
import cleopatraImg from "../../../assets/game_thumbnails/cleopatra.png";
import fortuneLionImg from "../../../assets/game_thumbnails/fortune-lion.png";
import fruit777Img from "../../../assets/game_thumbnails/fruit777.png";
import goldenWheelImg from "../../../assets/game_thumbnails/golden-wheel.png";
import kenoImg from "../../../assets/game_thumbnails/keno.png";
import lifeInLuxuryImg from "../../../assets/game_thumbnails/life-in-luxury.png";
import piratesOfCaribbeanImg from "../../../assets/game_thumbnails/pirates-of-caribbean.png";
import vegas777Img from "../../../assets/game_thumbnails/vegas777.png";
import xmasMagicImg from "../../../assets/game_thumbnails/xmas-magic.png";

export const TableDataMap = {
    "buffalo-safari": {
        tableImage: buffaloSafariImg,
        favIconPositionWeb: "top-[13.5%] right-[1%]",
        favIconPositionMob: "top-[12.5%] right-[1%]",
    },
    "cleopatra": { 
        tableImage: cleopatraImg,
        favIconPositionWeb: "top-[13.5%] right-[1.5%]",
        favIconPositionMob: "top-[12.5%] right-[1.5%]",
    },
    "china-street": {
        tableImage: chinaStreetImg,
        favIconPositionWeb: "top-[12.8%] right-[1.8%]",
        favIconPositionMob: "top-[11.8%] right-[1.8%]",
    },
    "fortune-lion": {
        tableImage: fortuneLionImg,
        favIconPositionWeb: "top-[13.5%] right-[1%]",
        favIconPositionMob: "top-[12.5%] right-[1%]",
    },
    "fruit777": {
        tableImage: fruit777Img,
        favIconPositionWeb: "top-[11.8%] right-[1.8%]",
        favIconPositionMob: "top-[11.8%] right-[1.8%]",
    },
    "golden-wheel": {
        tableImage: goldenWheelImg,
        favIconPositionWeb: "top-[13%] right-[1.8%]",
        favIconPositionMob: "top-[12%] right-[1.8%]",
    },
    "life-in-luxury": {
        tableImage: lifeInLuxuryImg,
        favIconPositionWeb: "top-[13.5%] right-[1.2%]",
        favIconPositionMob: "top-[12.5%] right-[1.2%]",
    },
    "pirates-of-caribbean": {
        tableImage: piratesOfCaribbeanImg,
        favIconPositionWeb: "top-[13.5%] right-[1.5%]",
        favIconPositionMob: "top-[12.5%] right-[1.5%]",
    },
    "keno": {
        tableImage: kenoImg,
        favIconPositionWeb: "top-[13%] right-[1.2%]",
        favIconPositionMob: "top-[12%] right-[1.8%]",
    },
    "vegas777": {
        tableImage: vegas777Img,
        favIconPositionWeb: "top-[13.5%] right-[1%]",
        favIconPositionMob: "top-[12.5%] right-[1.2%]",
    },
    "xmas-magic": {
        tableImage: xmasMagicImg,
        favIconPositionWeb: "top-[13.5%] right-[1.2%]",
        favIconPositionMob: "top-[12.5%] right-[1.2%]",
    },
}