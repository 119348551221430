import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import favActiveIcon from "../../assets/icons/fav_active_icon.png";
import favIcon from "../../assets/icons/fav_icon.png";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Table } from "../../store/tablesModel";
import { TableDataMap } from "./constants/tablesData";
import { SpriteAnimation } from "./SpriteAnimation";

interface GameTabProps {
  table: Table;
  favTables: string[];
  onGameClick: (gameId: string) => void;
  parentRef: React.RefObject<HTMLDivElement>;
}

const GameTab: React.FC<GameTabProps> = ({
  table,
  favTables,
  onGameClick,
  parentRef,
}) => {
  const dispatch = useDispatch();
  const [frameDimensions, setFrameDimensions] = useState({
    width: 335,
    height: 250,
  });
  const deviceType = localStorage.getItem("deviceType");

  useEffect(() => {
    const pfc = parentRef.current;
    const updateFrameSize = () => {
      if (parentRef.current) {
        const parentHeiight = parentRef.current.offsetHeight;
        const parentWidth = parentRef.current.offsetWidth;
        // Adjust frame size proportionally based on parent width
        let mFactor = 0.23;
        if (deviceType !== "desktop" && parentHeiight > 500) {
          mFactor = 0.31;
        }
        const newWidth = parentWidth * mFactor;
        const newHeight = (newWidth * 250) / 350; // Maintain aspect ratio
        setFrameDimensions({
          width: newWidth,
          height: newHeight,
        });
      }
    };

    // Use ResizeObserver to dynamically track the size of the parent element
    const observer = new ResizeObserver(updateFrameSize);

    if (pfc) {
      observer.observe(pfc);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (pfc) {
        observer.unobserve(pfc);
      }
    };
  }, [parentRef, deviceType]);

  const handleFavClick = async () => {
    try {
      const response = await axios.post(
        "https://backend.inferixai.link/api/favorites",
        {
          tableId: table.tableId,
          isFav: !favTables.includes(table.tableId),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.data && response.data.status === "RS_OK") {
        // Dispatch to Redux store
        const _fTables = response.data.favTables;
        dispatch(favTablesLoaded(_fTables));
      } else {
        throw new Error(response.data.message);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <div
      className={`relative flex z-10 h-fit justify-end sm:ml-[4px] hover:scale-[1.08]`}
      key={table.tableId + "_game"}
    >
      <SpriteAnimation
        spriteSheetImage={`game_thumbnail_sprites/${table.slug}.png`}
        frameWidth={frameDimensions.width}
        frameHeight={frameDimensions.height}
        onClick={() => onGameClick(table.slug)}
      />
      <img
        className={`cursor-pointer p-1 absolute ${
          window.innerWidth > 1200
            ? `w-[40px] ${
                TableDataMap[table.slug as keyof typeof TableDataMap]
                  .favIconPositionWeb
              } mr-[2.8%]`
            : `w-[32px] ${
                TableDataMap[table.slug as keyof typeof TableDataMap]
                  .favIconPositionMob
              } mr-[2%]`
        }`}
        src={favTables.includes(table.tableId) ? favActiveIcon : favIcon}
        alt="favorite"
        onClick={handleFavClick}
      />
    </div>
  );
};

export default GameTab;
