import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../utils/buttons/Button";
import Modal from "../utils/modal/Modal";

interface LogoutProps {
  isOpen: boolean;
  onClose: () => void;
}

const Logout: React.FC<LogoutProps> = ({ isOpen, onClose }) => {
  const deviceType = localStorage.getItem("deviceType");
  const navigate = useNavigate();

  const logoutUser = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.warn("No session token found. Redirecting to login.");
        return navigate("/login");
      }

      const response = await axios.post(
        "https://backend.inferixai.link/api/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.data) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("username");
        navigate("/login");
        console.log("Logout successful.");
      } else {
        console.error("Logout failed. Code:", response.data.code);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Logout">
      <div
        className={`flex flex-col w-3/4 mx-auto ${
          deviceType === "desktop" ? "mt-8" : "mt-4"
        }`}
      >
        <div
          className={`gradient-text text-center ${
            deviceType === "desktop"
              ? "text-[32px] font-bold"
              : "text-2xl font-semibold"
          }`}
        >
          Are you sure you want to logout?
        </div>
        <div
          className={`flex w-full justify-center ${
            deviceType === "desktop" ? "mt-10" : "mt-8"
          }`}
        >
          <Button text="Logout" onClick={() => logoutUser()} />
        </div>
      </div>
    </Modal>
  );
};

export default Logout;
