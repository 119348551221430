import "./OverlaySpinner.css";

interface OverlaySpinnerProps {
  text?: string;
}

export default function OverlaySpinner({ text }: OverlaySpinnerProps) {
  return (
    <div className="spinner-overlay rounded-lg flex flex-col">
      <div className="spinner"></div>
      {text && (
        <div className="text-white font-medium text-md mt-4">{text}</div>
      )}
    </div>
  );
}
