import { useEffect, useState } from "react";

interface SpriteAnimationProps {
  spriteSheetImage: string;
  frameWidth: number;
  frameHeight: number;
  fps?: number;
  onClick?: () => void;
  classes?: string;
}

export function SpriteAnimation({
  spriteSheetImage,
  frameWidth,
  frameHeight,
  fps = 16,
  onClick,
  classes,
  children,
}: SpriteAnimationProps & { children?: React.ReactNode }) {
  const [currentFrame, setCurrentFrame] = useState(0);
  const TOTAL_FRAMES = 25; // 5x5 grid
  const ROWS = 5;
  const COLS = 5;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentFrame((prev) => (prev + 1) % TOTAL_FRAMES);
    }, 1000 / fps);

    return () => clearInterval(intervalId);
  }, [fps]);

  const row = Math.floor(currentFrame / COLS);
  const col = currentFrame % COLS;

  const spriteSheet = require(`../../assets/${spriteSheetImage}`);

  return (
    <div
      className={`relative ${onClick ? "cursor-pointer" : ""} ${classes}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      style={{
        width: frameWidth,
        height: frameHeight,
        backgroundImage: `url(${spriteSheet})`,
        backgroundPosition: `-${col * frameWidth}px -${row * frameHeight}px`,
        backgroundSize: `${frameWidth * COLS}px ${frameHeight * ROWS}px`,
        imageRendering: "pixelated",
      }}
    >
      {children}
    </div>
  );
}
