import { useEffect, useRef, useState } from "react";
import AudioSettings from "../AudioSettings";
import PopoverMenu from "../PopoverMenu";
import { SpriteAnimation } from "../SpriteAnimation";
import Modal from "../modal/Modal";
import "./TopPanel.css";

export default function TopPanel() {
  const deviceType = localStorage.getItem("deviceType");
  const [balance, setBalance] = useState<number>();
  const [isAudioSettingsOpen, setIsAudioSettingsOpen] =
    useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [frameDimensions, setFrameDimensions] = useState({
    width: 52,
    height: 52,
  });

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found in session storage.");
      return;
    }

    const ws = new WebSocket(
      `wss://backend.inferixai.link/user/auth?authorization=Bearer ${token}`
    );

    ws.onopen = () => {
      console.log("WebSocket connection opened.");

      // Request balance immediately upon connection
      ws.send(JSON.stringify({ operation: "getbalance" }));

      // Request balance every 10 seconds
      const intervalId = setInterval(() => {
        ws.send(JSON.stringify({ operation: "getbalance" }));
      }, 10000);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const balanceData = data.data;
        if (balanceData && balanceData.balance !== undefined) {
          setBalance(balanceData.balance);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed.");
        clearInterval(intervalId);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        clearInterval(intervalId);
      };

      // Cleanup on unmount
      return () => {
        clearInterval(intervalId);
        ws.close();
      };
    };
  }, []);

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the popover
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopoverOpen]);

  useEffect(() => {
    const pfc = parentRef.current;
    const updateFrameSize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        // Adjust frame size proportionally based on parent width
        let mFactor = 0.0575 * 0.01 * parentWidth;
        if (deviceType !== "desktop" && parentWidth > 500) {
          mFactor = 0.08 * 0.01 * parentWidth;
        }
        const newWidth = deviceType === "desktop" ? 85 * mFactor : 52;
        const newHeight = newWidth; // Maintain aspect ratio
        setFrameDimensions({
          width: newWidth,
          height: newHeight,
        });
      }
    };

    // Use ResizeObserver to dynamically track the size of the parent element
    const observer = new ResizeObserver(updateFrameSize);

    if (pfc) {
      observer.observe(pfc);
    }

    // Cleanup the observer on component unmount
    return () => {
      if (pfc) {
        observer.unobserve(pfc);
      }
    };
  }, [parentRef, deviceType]);

  return (
    <div
      ref={parentRef}
      className={`flex flex-col bg-[url(./assets/top_panel.png)] bg-no-repeat bg-top bg-cover p-0 pb-2 pt-[6px] z-[100] ${
        deviceType === "desktop" ? "h-[125px]" : "h-[85px]"
      }`}
    >
      <div
        className={`flex w-full h-full justify-between items-baseline ${
          deviceType === "desktop" ? "mt-[6px]" : "mt-[2px]"
        }`}
      >
        <div className="flex justify-start ml-2 items-center">
          <div
            className={`flex items-center ${
              deviceType === "desktop" ? "mr-6" : "mr-3"
            }`}
          >
            <SpriteAnimation
              spriteSheetImage={`sprites/profile.png`}
              frameWidth={frameDimensions.width}
              frameHeight={frameDimensions.height}
            />
            <div
              className={`font-semibold top-panel-gradient-text cursor-default ${
                deviceType === "desktop" ? "text-xl" : "text-sm"
              }`}
            >
              ID: {sessionStorage.getItem("username")}
            </div>
          </div>
          <div className="flex items-center -mt-[6px]">
            <SpriteAnimation
              spriteSheetImage={`sprites/dollar_sign.png`}
              frameWidth={frameDimensions.width * 0.75}
              frameHeight={frameDimensions.height * 0.75}
            />
            <div
              className={`font-semibold top-panel-gradient-text cursor-default ml-1 ${
                deviceType === "desktop" ? "mt-2 text-[32px]" : "mt-1 text-xl"
              }`}
            >
              {balance ? balance.toFixed(2) : "0.00"}
            </div>
          </div>
        </div>
        <div className="flex justify-end pr-3">
          <SpriteAnimation
            spriteSheetImage={`sprites/menu.png`}
            frameWidth={frameDimensions.width}
            frameHeight={frameDimensions.height}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            classes={deviceType === "desktop" ? "mr-3" : "mr-1"}
          />
          <SpriteAnimation
            spriteSheetImage={`sprites/settings.png`}
            frameWidth={frameDimensions.width}
            frameHeight={frameDimensions.height}
            onClick={() => setIsAudioSettingsOpen(!isPopoverOpen)}
          />
        </div>
      </div>

      <Modal
        isOpen={isAudioSettingsOpen}
        onClose={() => setIsAudioSettingsOpen(false)}
        title="Settings"
      >
        <AudioSettings />
      </Modal>
      <PopoverMenu
        ref={popoverRef}
        isOpen={isPopoverOpen}
        toggleMenu={() => setIsPopoverOpen(false)}
      />
    </div>
  );
}
