import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import GameTab from "../../components/utils/GameTab";
import RotatePhone from "../../components/utils/RotatePhone";
import SidePanel from "../../components/utils/SidePanel";
import TopPanel from "../../components/utils/topPanel/TopPanel";
import { favTablesLoaded } from "../../store/favTablesSlice";
import { Filters } from "../../store/filtersModel";
import { filtersUpdated } from "../../store/filtersSlice";
import { RootState } from "../../store/store";
import { Table } from "../../store/tablesModel";
import { tablesFiltered, tablesLoaded } from "../../store/tablesSlice";

export default function Lobby() {
  const navigate = useNavigate();
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const [isPortrait, setIsPortrait] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const parentRef = useRef<HTMLDivElement>(null);
  // Get tables from Redux store
  const tables = useSelector((state: RootState) => state.tables);
  const favTables = useSelector((state: RootState) => state.favTables);
  const filteredTables = useSelector((state: RootState) =>
    tablesFiltered(state, filters, favTables)
  );

  useEffect(() => {
    const filtersParam = searchParams.get("filters");
    if (!filtersParam) {
      navigate(`/lobby?filters=${encodeURIComponent(JSON.stringify(filters))}`);
    }
  }, [searchParams, filters, navigate]);

  useEffect(() => {
    const filtersParam = searchParams.get("filters");
    if (filtersParam) {
      try {
        const _filters: Filters = JSON.parse(filtersParam);
        dispatch(filtersUpdated(_filters));
      } catch (error) {
        console.error("Failed to parse filters from URL", error);
      }
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    const getTables = async () => {
      setError("");

      try {
        const response = await axios.get(
          "https://backend.inferixai.link/api/gettables",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.data && response.data.status === "RS_OK") {
          const fetchedTables: Table[] = response.data.tables.map(
            (table: any) => ({
              tableId: table.tableId,
              tableName: table.tableName,
              category: table.category,
              slug: table.slug,
              orientation: table.orientation as
                | "landscape-primary"
                | "portrait-primary",
            })
          );

          // Dispatch to Redux store
          dispatch(tablesLoaded(fetchedTables));
          dispatch(favTablesLoaded(response.data.favTables));
        } else {
          throw new Error(response.data.message);
        }
      } catch (err: any) {
        console.error(err);
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error);
        } else {
          setError("Failed to load tables");
        }
      }
    };

    // Fetch tables only if they are not in the store
    if (tables.length === 0) {
      getTables();
    }
  }, [tables, dispatch]);

  useEffect(() => {
    // Check and set orientation
    const checkOrientation = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    checkOrientation();
    window.addEventListener("resize", checkOrientation);

    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  const redirectToSlotGames = (gameId: string) => {
    navigate(`/slot-games/${gameId}`);
  };

  return (
    <div className="flex h-full flex-col">
      {isPortrait && (
        <RotatePhone orientation={"landscape-primary"}></RotatePhone>
      )}

      {!isPortrait && (
        <>
          <TopPanel></TopPanel>
          <div className="flex w-full h-[88%] overflow-hidden">
            <div className="flex w-[12%] h-auto sticky">
              <SidePanel></SidePanel>
            </div>
            <div className="flex w-[90%] h-full" ref={parentRef}>
              <div className="flex w-full h-full flex-col p-2 pt-0 sm:p-4 sm:pt-0 sm:pl-0 overflow-y-auto">
                {error && (
                  <div className="flex w-full p-2 mt-3 text-red-600 text-3xl font-medium justify-center">
                    {error}
                  </div>
                )}
                <div className="flex flex-wrap p-1 pt-0 mb-[1%] sm:pt-0">
                  {filteredTables.map((_g: Table) => (
                    <GameTab
                      key={`${_g.tableId}_tab`}
                      table={_g}
                      favTables={favTables}
                      onGameClick={(gameId: string) =>
                        redirectToSlotGames(gameId)
                      }
                      parentRef={parentRef}
                    ></GameTab>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
