import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Filters } from './filtersModel';
import { Table } from './tablesModel';

const initialState: Table[] = [];

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    tableAdded(state, action: PayloadAction<Table>) {
      state.push(action.payload);
    },
    tablesLoaded(state, action: PayloadAction<Table[]>) {
      return action.payload;
    },
  },
  selectors: {
    tablesFiltered: (tablesState, filters: Filters, favTables?: string[], excludeTableId?: string) => {
      let tables = tablesState;
      if (filters.tables) {
        switch(filters.tables) {
          case 'all': tables = tablesState; break;
          case 'favs': tables = tablesState.filter(_t => favTables?.includes(_t.tableId)); break;
          case 'slots': tables = tablesState.filter(_t => _t.category === 'slot'); break;
        }
      }
      if (excludeTableId) {
        tables = tables.filter(_t => _t.tableId !== excludeTableId);
      }
      return [...tables].sort((a, b) => a.tableName.localeCompare(b.tableName));
    }
  }
});

export const { tableAdded, tablesLoaded } = tablesSlice.actions;
export const { tablesFiltered } = tablesSlice.selectors;
export default tablesSlice.reducer;
