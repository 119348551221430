import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import homeIcon from "../../assets/icons/homeIcon.svg";
import { RootState } from "../../store/store";
import { tablesFiltered } from "../../store/tablesSlice";
import { TableDataMap } from "./constants/tablesData";

export default function SideNav({
  excludeTableId,
}: {
  excludeTableId?: string;
}) {
  const navigate = useNavigate();
  const favTables = useSelector((state: RootState) => state.favTables);
  const filters = useSelector((state: RootState) => state.filters);
  const filteredTables = useSelector((state: RootState) =>
    tablesFiltered(state, filters, favTables, excludeTableId)
  );

  const reditectTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full items-baseline p-4">
        <div
          className="flex flex-col cursor-pointer justify-center items-center w-min p-1"
          onClick={() => reditectTo("/lobby")}
        >
          <img src={homeIcon} alt="Lobby" />
          <div className="text-xs text-white font-bold">Lobby</div>
        </div>
        <div className="text-2xl mx-auto font-extrabold text-white">
          Slot Games
        </div>
      </div>
      <div className="flex flex-col p-3 max-h-[90%] overflow-y-auto">
        {filteredTables.map((_table) => (
          <div
            className="flex flex-col w-[95%] mt-2 hover:scale-[1.07]"
            key={_table.tableId + "_game_block"}
          >
            <img
              className="cursor-pointer rounded-xl shadow-md"
              src={
                TableDataMap[_table.slug as keyof typeof TableDataMap]
                  .tableImage
              }
              alt={_table.tableId}
              key={_table.tableId + "_game_img"}
              onClick={() => reditectTo(`/slot-games/${_table.slug}`)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
