import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "../../store/tablesModel";
import CustomScreenOrientation from "../utils/constants/screenOrientation";
import "./GamePlayer.css";

declare global {
  interface Window {
    redirectToHome: () => void;
    setMusicVolume: (val: number) => void;
    setSoundVolume: (val: number) => void;
  }
}

export default function UnityWebGLPlayer(props: { table: Table }) {
  const { table } = props;
  const canvasRef = useRef<HTMLCanvasElement>(document.createElement("canvas"));
  const containerRef = useRef<HTMLDivElement>(null);
  const unityInstanceRef = useRef<any>(null);
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const deviceType = localStorage.getItem("deviceType");
  const username = sessionStorage.getItem("username");

  const enterFullScreen = () => {
    const container = containerRef.current;

    if (!container) return;

    const requestFullscreen =
      container.requestFullscreen ||
      (container as any).mozRequestFullScreen || // Firefox
      (container as any).webkitRequestFullscreen || // Safari
      (container as any).msRequestFullscreen; // IE/Edge

    if (requestFullscreen) {
      requestFullscreen.call(container).catch((err: any) => {
        console.error(
          `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      console.error("Fullscreen API is not supported on this browser.");
    }
  };

  const setLockOrientation = (
    orientation: "landscape-primary" | "portrait-primary"
  ) => {
    const screenOrientation = (window.screen.orientation ||
      (window.screen as any).mozOrientation ||
      (window.screen as any).msOrientation) as CustomScreenOrientation;

    if (screenOrientation && screenOrientation.lock) {
      screenOrientation.lock(orientation).catch((err: any) => {
        console.error("Orientation lock failed:", err);
      });
    } else if ((window.screen as any).lockOrientation) {
      (window.screen as any).lockOrientation(orientation).catch((err: any) => {
        console.error("Orientation lock failed:", err);
      });
    } else {
      console.error("Orientation lock is not supported on this device.");
    }
  };

  // Expose the function to the global scope
  useEffect(() => {
    const redirectToHome = () => {
      navigate("/lobby");
    };

    const setMusicVolume = (val: number) => {
      Cookies.set(`${username}_music`, val.toFixed(2), { expires: 365 });
    };

    const setSoundVolume = (val: number) => {
      Cookies.set(`${username}_sound`, val.toFixed(2), { expires: 365 });
    };

    window.redirectToHome = redirectToHome;
    window.setMusicVolume = setMusicVolume;
    window.setSoundVolume = setSoundVolume;
  }, [navigate, username]);

  useEffect(() => {
    // Add fullscreen exit listener
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        navigate("/lobby");
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [navigate]);

  useEffect(() => {
    setShowSpinner(true);

    const resizeCanvas = (
      orientation: "landscape-primary" | "portrait-primary"
    ) => {
      const canvas = canvasRef.current;
      const container = containerRef.current;
      if (canvas && container) {
        const { clientWidth, clientHeight } = container;
        if (orientation === "landscape-primary") {
          canvas.style.width = `${clientWidth}px`;
          canvas.style.height = `${Math.min(clientHeight, 640)}px`;
        } else {
          canvas.style.width =
            deviceType === "desktop"
              ? `${Math.min(clientWidth, 428)}px`
              : `${clientWidth}px`;
          canvas.style.height = `${clientHeight}px`;
        }
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
      }
    };

    const replaceCanvas = () => {
      const container = containerRef.current;
      if (container && canvasRef.current) {
        // Remove the old canvas element
        container.removeChild(canvasRef.current);

        // Create a new canvas element
        const newCanvas = document.createElement("canvas");
        newCanvas.id = "unity-canvas";
        newCanvas.className = "m-0 sm:mx-auto z-50";

        // Set the canvas dimensions based on the container's dimensions
        newCanvas.width = container.clientWidth;
        newCanvas.height = container.clientHeight;

        // Append the new canvas to the container
        container.appendChild(newCanvas);

        // Manually set the reference's current value to the new canvas
        canvasRef.current = newCanvas;

        // Resize the canvas to ensure proper display
        if (table) {
          resizeCanvas(table.orientation);
        }
      }
    };

    // Cleanup the previous Unity instance if it exists
    if (unityInstanceRef.current) {
      unityInstanceRef.current.Quit().then(() => {
        console.log("Unity instance successfully quit.");
        unityInstanceRef.current = null;
        replaceCanvas();
      });
    }

    const buildUrl = `/UnityBuilds/${table.slug}`;
    const loaderUrl = `${buildUrl}/Build.loader.js`;
    const config = {
      dataUrl: `${buildUrl}/Build.data.unityweb`,
      frameworkUrl: `${buildUrl}/Build.framework.js.unityweb`,
      codeUrl: `${buildUrl}/Build.wasm.unityweb`,
      companyName: "slot-games",
      productName: table.tableName,
      productVersion: "1.0",
    };

    const script = document.createElement("script");

    if (containerRef.current && canvasRef.current) {
      if (deviceType === "mobile" || deviceType === "tablet") {
        enterFullScreen();
      }

      if (table) {
        if (deviceType !== "desktop") {
          setLockOrientation(table.orientation);
        }
        resizeCanvas(table.orientation);
      }

      window.addEventListener("resize", () =>
        resizeCanvas(table?.orientation || "landscape-primary")
      );

      script.src = loaderUrl;
      script.onload = () => {
        if (typeof createUnityInstance !== "undefined" && canvasRef.current) {
          createUnityInstance(canvasRef.current, config, (progress) => {
            setShowSpinner(progress !== 1);
          })
            .then((unityInstance) => {
              unityInstanceRef.current = unityInstance;
              if (sessionStorage.getItem("token")) {
                unityInstance.SendMessage(
                  "GameManager",
                  "OnJSSessionVarReceived",
                  sessionStorage.getItem("token")
                );
                setTimeout(() => {
                  unityInstance.SendMessage(
                    "GameManager",
                    "MusicSettingsFromWeb",
                    parseFloat(Cookies.get(`${username}_music`) || "0.50")
                  );
                  unityInstance.SendMessage(
                    "GameManager",
                    "SoundSettingsFromWeb",
                    parseFloat(Cookies.get(`${username}_sound`) || "0.50")
                  );
                }, 5000);
              }
            })
            .catch((message) => {
              alert(message);
            })
            .finally(() => {
              setShowSpinner(false);
            });
        } else {
          console.error("createUnityInstance is not defined");
        }
      };
      document.body.appendChild(script);
    }

    return () => {
      // Cleanup on component unmount
      if (unityInstanceRef.current) {
        unityInstanceRef.current.Quit().then(() => {
          console.log("Unity instance successfully quit.");
          unityInstanceRef.current = null;
          replaceCanvas();
        });
      }
      document.body.removeChild(script);
      window.removeEventListener("resize", () =>
        resizeCanvas("landscape-primary")
      );
    };
  }, [table, deviceType, username]);

  return (
    <div
      id="unity-container"
      className="flex flex-col w-full h-full mt-0"
      ref={containerRef}
    >
      <canvas
        id="unity-canvas"
        className="m-0 sm:mx-auto z-50"
        ref={canvasRef}
      ></canvas>
      {showSpinner && (
        <div
          className={`flex w-full justify-center p-8 absolute 
        ${
          deviceType === "mobile" || deviceType === "tablet"
            ? "sm:top-[38%] sm:left-[0%]"
            : "sm:top-[32%] sm:left-[5%]"
        }`}
        >
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
}
