import { useState } from "react";
import LoginForm from "../../components/auth/LoginForm";
import SignUpForm from "../../components/auth/SignupForm";
import OverlaySpinner from "../../components/utils/spinners/OverlaySpinner/OverlaySpinner";
import "./Login.css";

export default function Page() {
  const [showSignup, setShowSignup] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  return (
    <div className="flex w-full h-full">
      <div className="flex pt-8 pb-6 w-11/12 sm:w-3/12 sm:min-w-[480px]  mx-auto perspective overflow-auto">
        <div
          className={`form-container flex h-fit ${showSignup ? "flipped" : ""}`}
        >
          <div className="form-side front shadow-lg rounded-lg p-[22px] pb-[32px] my-auto bg-[#000000B2]">
            {showSpinner && <OverlaySpinner />}
            <LoginForm showSpinner={(val: boolean) => setShowSpinner(val)} />
            <div className="flex font-medium text-xs leading-[14.5px] items-baseline justify-center">
              Not Registered?
              <span
                className="text-sm leading-[16.94px] cursor-pointer font-bold px-1 hover:scale-110 underline"
                onClick={() => setShowSignup(true)}
              >
                SignUp
              </span>
            </div>
          </div>
          <div className="form-side back shadow-lg rounded-lg p-[22px] pb-[32px] bg-[#000000B2]">
            {showSpinner && <OverlaySpinner />}
            <SignUpForm
              onSuccess={() => setShowSignup(false)}
              showSpinner={(val: boolean) => setShowSpinner(val)}
            />
            <div className="flex font-medium text-xs leading-[14.5px] items-baseline justify-center">
              Already Registered?
              <span
                className="text-sm leading-[16.94px] cursor-pointer font-bold px-1 hover:scale-110 underline"
                onClick={() => setShowSignup(false)}
              >
                Login
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
